<template>
  <div class="checkout">
    <b-container fluid>
      <b-row>
        <b-col lg="12">
          <iq-card>
            <!-- <template v-slot:headerTitle>
            <h4 class="card-title">劳动法参数查询</h4>
          </template> -->
            <template v-slot:body>
              <div class="iq-search-bar">
                <p class="all-crumbs">劳动法规检索 > 劳动法参数查询</p>
                <form action="#" class="">
                  <p>
                    劳动法数据筛选结果 ：已默认数据
                    <span>{{ new Date(dateVal).getFullYear() }}年</span>
                    ，
                    <span>{{ cityCount }}</span>
                    个区域，
                    <span>{{ targetCount }}</span>
                    个指标
                  </p>
                  <!-- <label for="validationDefault01">关键字</label>
                <input
                  type="text"
                  class="text search-input"
                  v-model="globalSearch"
                  placeholder="请输入关键词"
                />
                <el-button
                  class="btn mr-1 btn-primary rounded-pill seeAbout"
                  type="submit"
                >
                  查询
                </el-button>
                <el-button
                  class="btn mr-1 btn-secondary rounded-pill reset"
                  type="submit"
                >
                  重置
                </el-button> -->
                  <span style="flex: 1"></span>
                  <span style="margin-left: 50px">选择年份</span>
                  <!-- <el-date-picker
                  v-model="value2"
                  type="month"
                  value-format="yyyy-MM"
                  @change="seleteDate"
                  placeholder="选择月">
                </el-date-picker> -->
                  <el-date-picker
                    v-model="dateVal"
                    type="year"
                    size="small"
                    placeholder="选择年份"
                    prefix-icon="el-icon-date"
                    format="yyyy年"
                    value-format="yyyy年"
                    @change="seleteDate"
                  >
                  </el-date-picker>
                  <!-- <div class="card">
                  <b-button pill variant="outline-primary" class="mb-3 mr-1"
                    >下载数据报表</b-button
                  >
                </div> -->
                </form>
              </div>
            </template>
          </iq-card>
        </b-col>
        <b-col lg="2">
          <div class="filt-box">
            <div
              :class="`card card${index}`"
              v-for="(item, index) in myList"
              :key="item.title"
            >
              <div class="title">
                <img :src="item.url" alt="" />
                <p>{{ item.label }}</p>
              </div>
              <div class="select-box">
                <div class="card-box" v-if="index == 0">
                  <el-checkbox-group v-model="checkList" @change="selectList">
                    <el-checkbox
                      v-for="item2 in item.children"
                      :key="item2.id"
                      :label="item2.name"
                    ></el-checkbox>
                  </el-checkbox-group>
                </div>
                <el-tree
                  v-if="index == 1"
                  :data="item.children"
                  ref="tree"
                  show-checkbox
                  node-key="id"
                  @check="handleCheckChange($event)"
                  :props="defaultProps"
                  :check-on-click-node="true"
                  :check-strictly="true"
                >
                </el-tree>
              </div>
            </div>
          </div>
        </b-col>

        <b-col lg="10">
          <div class="form-box">
            <div class="table-container">
              <el-table
                ref="table"
                border
                :data="tableData"
                style="width: 100%"
                fit
              >
                <template v-for="head in allHeadData">
                  <el-table-column
                    :key="head.id"
                    :label="head.label"
                    :prop="head.prop"
                    :align="head.align"
                    :width="flexWidth(head.prop, tableData, head.label)"
                    :render-header="renderHeader"
                  >
                    <template
                      v-for="childrenHead in head.childs"
                    >
                      <el-table-column
                        :key="childrenHead.id"
                        :label="childrenHead.label"
                        :align="childrenHead.align"
                        :prop="childrenHead.prop"
                        :width="
                          flexWidth(
                            childrenHead.prop,
                            tableData,
                            childrenHead.label
                          )
                        "
                        :render-header="renderHeader"
                      >
                      </el-table-column>
                    </template>
                  </el-table-column>
                </template>
              </el-table>
              <div>
                <el-pagination
                  @size-change="handleSizeChange"
                  @current-change="handleCurrentChange"
                  :current-page="currentPage"
                  :page-sizes="[10, 20, 30]"
                  :page-size="interPage"
                  layout="total, sizes, prev, pager, next, jumper"
                  background
                  :total="dataSize"
                >
                </el-pagination>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>
<script>
import { core } from '../../../config/pluginInit'
import { Loading } from 'element-ui'
// 引入函数
import { cjcaseparameter, getLaborLawParamsForm } from '@/FackApi/api/index'
import { getRegion, getTarget, getLaborlawparams } from '@/FackApi/api/admin'
export default {
  name: 'Checkout',

  mounted () {
    core.index()
  },
  data () {
    return {
      normCheckbox: [], // 指标
      areaCheckbox: [], // 地区
      yearCheckbox: [], // 年份
      selectedAge1: null,
      options1: [],
      selectedAge2: null,
      options2: [],

      checkVal: [],
      excelFileType: [],
      globalSearch: '',
      showSearch: false,
      showMenu: false,
      options: [
        { id: 1, label: 'foo' },
        { id: 3, label: 'bar' },
        { id: 2, label: 'baz' }
      ],
      selected: { id: 3, label: 'bar' },
      selectedselection: ['foo', 'bar'],
      optionsselection: ['foo', 'bar', 'baz'],
      formSelectList: [],
      data1: null,
      data2: null,
      basicConfig: {},
      list: [
        {
          province: '北京市',
          city: '北京市',
          year: '2022',
          CPIindex: '102.2',
          CPItime: '2022年7月',
          GDPindex: '9542.2',
          GDPtime: '2022第一季度',
          month: '2030',
          hour: '13.5',
          ismonth: '50',
          executetime: '2022年7月10日'
        }
      ],

      myList: [],
      defaultProps: {
        children: 'childs',
        label: 'name'
      },
      data: [],
      pList: [],
      checkList: [],
      checkList2: [],
      ayList: [],
      allList: [],
      tableData: [],
      value2: '',
      areaInfo: {
        province: [],
        city: {}
      },
      provinceId: '',
      provinceVal: '',
      cityId: '',
      cityVal: '',
      areaList: [],
      targetList: [],
      allAreaNum: 0,
      allTargetNum: 0,

      targetList: [],
      dateVal: new Date(), // 年份
      cityCount: 0, // 城市数量
      targetCount: 0, // 指标数量
      tableHeight: 600,
      dataSize: 0, // 总记录条数
      currentPage: 1, // 分页默认页数
      interPage: 20, // 每页显示条数
      tableData: [],
      allHeadData: [],
      arrTableLength: {},
      countryList: [],
      provinceList: [],
      cityList: []
    }
  },
  created () {
    // this.getAppForm();
    // var date = new Date();
    // this.value2 += date.getFullYear();
    // this.value2 += "-" + (date.getMonth()+1);
    // this.seleteDate();
    this.getTargetInfo()
  },
  methods: {
    // 获取列表数据
    async getDataInfo () {
      const loadingInstance = Loading.service({ fullscreen: true })
      this.targetCount =
        this.targetList.length || this.myList[0].children.length
      this.allHeadData = [
        { align: "center", prop: "country", label: "国家" },
        { align: 'center', prop: 'provinceName', label: '省份/直辖市' },
        { align: 'center', prop: 'cityName', label: '城市' },
        // { align: "center", prop: "area", label: "地区" },
        { align: 'center', prop: 'year', label: '年份' }
      ]
      const data = {
        targetIds: this.targetList,
        provinceIds: this.provinceList,
        cityIds: this.cityList,
        year: this.dateVal.getFullYear()
      }
      const params = {
        data: data,
        paging: `${this.currentPage}/${this.interPage}`
      }
      const res = await getLaborlawparams(params)
      if (res.code == 0) {
        const head = res.result.columns
        head.forEach((item) => {
          const newArr = []
          if (item.childs && item.childs.length > 0) {
            item.childs.forEach((item2) => {
              newArr.push({
                align: 'center',
                prop: item2.code,
                label: item2.name
              })
            })
          }
          this.allHeadData.push({
            align: 'center',
            prop: item.code,
            label: item.name,
            childs: newArr
          })
        })
        const data = res.result.datas ? res.result.datas.items : []
        data.forEach((item3) => {
          item3.country = "中国";
          if (!item3.cityName && item3.provinceName) {
            let type = item3.provinceName.substr(-1);
            item3.cityName = `全${type}`
          }
          item3.datas.forEach((item4) => {
            item3[item4.code] = item4.value
          })
        })
        this.tableData = data
        const total = res.result.datas ? res.result.datas.totalCount : 0
        this.dataSize = total
        this.cityCount = total
        this.$nextTick(() => {
          this.$refs.table.doLayout()
        })
      }
      loadingInstance.close()
    },
    // 获取指标
    async getTargetInfo () {
      const res = await getTarget()
      if (res.code == 0) {
        this.myList = []
        const data = res.result
        data.forEach((val) => {
          val.childs = null
        })
        this.myList.push({
          url: require('@/assets/images/fgzck/icon-4.png'),
          label: '指标',
          children: data.reverse()
        })
      }
      const res2 = await getRegion()
      if (res2.code == 0) {
        const treeLi = res2.result
        treeLi.forEach((val) => {
          if (val.childs.length == 1 && val.childs[0].name == val.name) {
            val.childs = null
          }
        })
        this.myList.push({
          url: require('@/assets/images/fgzck/icon-2.png'),
          label: '地区',
          children: treeLi
        })
      }
      this.getDataInfo()
    },
    handleCheckChange (data) {
      let _findIndex = ''
      if (data.level == 1) {
        _findIndex = this.provinceList.findIndex((item) => {
          return item == data.id
        })
        if (_findIndex === -1) {
          this.provinceList.push(data.id)
        } else {
          this.provinceList.splice(_findIndex, 1)
        }
      } else if (data.level == 2) {
        _findIndex = this.cityList.findIndex((item) => {
          return item == data.id
        })
        if (_findIndex === -1) {
          this.cityList.push(data.id)
        } else {
          this.cityList.splice(_findIndex, 1)
        }
      }
      this.getDataInfo()
    },
    selectList () {
      this.targetList = []
      const newArr = this.myList[0].children
      this.checkList.forEach((item) => {
        for (const i in newArr) {
          const item2 = newArr[i]
          if (item2.name == item) {
            this.targetList.push(item2.id)
            break
          }
        }
      })
      this.getDataInfo()
    },
    seleteDate () {
      this.dateVal = new Date(this.dateVal.slice(0, 4))
      this.getDataInfo()
    },
    handleSizeChange (val) {
      this.currentPage = 1
      this.interPage = val
      this.getDataInfo()
    },
    handleCurrentChange (val) {
      this.currentPage = val
      this.getDataInfo()
    },
    // 表头部重新渲染
    renderHeader (h, { column, $index }) {
      // 新建一个 span
      const span = document.createElement('span')
      // 设置表头名称
      span.innerText = column.label
      // 临时插入 document
      document.body.appendChild(span)
      // 重点：获取 span 最小宽度，设置当前列，注意这里加了 20，字段较多时还是有挤压，且渲染后的 div 内左右 padding 都是 10，所以 +20 。（可能还有边距/边框等值，需要根据实际情况加上）
      column.minWidth = span.getBoundingClientRect().width + 20
      // 移除 document 中临时的 span
      document.body.removeChild(span)
      return h('span', column.label)
    },
    flexWidth (prop, tableData, title, num = 0) {
      // console.log(tableData);
      if (tableData.length === 0) {
        // 表格没数据不做处理
        return
      }
      let flexWidth = 0 // 初始化表格列宽
      let columnContent = '' // 占位最宽的内容
      const canvas = document.createElement('canvas')
      const context = canvas.getContext('2d')
      context.font = '14px Microsoft YaHei'
      if (prop === '' && title) {
        // 标题长内容少的，取标题的值,
        columnContent = title
      } else {
        // 获取该列中占位最宽的内容
        let index = 0
        for (let i = 0; i < tableData.length; i++) {
          const now_temp = tableData[i][prop] + ''
          const max_temp = tableData[index][prop] + ''
          const now_temp_w = context.measureText(now_temp).width
          const max_temp_w = context.measureText(max_temp).width
          if (now_temp_w > max_temp_w) {
            index = i
          }
        }
        columnContent = tableData[index][prop]
        // 比较占位最宽的值跟标题、标题为空的留出四个位置
        const column_w = context.measureText(columnContent).width
        const title_w = context.measureText(title).width
        if (column_w < title_w) {
          columnContent = title || '留四个字'
        }
      }
      // 计算最宽内容的列宽
      const width = context.measureText(columnContent)
      flexWidth = width.width + 40 + num
      // console.log(flexWidth);
      return flexWidth + 'px'
    },
    // 获取左侧筛选条件
    getAppForm () {
      cjcaseparameter().then((res) => {
        if (res.code === 0) {
          // let data = res;
          this.allAreaNum = 0
          this.allTargetNum = res.indexList.length
          res.calcitiesList.forEach((item) => {
            this.allAreaNum += item.children.length
          })
          this.myList = [
            {
              url: require('@/assets/images/fgzck/icon-4.png'),
              label: '指标',
              children: res.indexList
            },
            {
              url: require('@/assets/images/fgzck/icon-2.png'),
              label: '地区',
              children: res.calcitiesList
            }
          ]
        }
      })
    },
    // 选择省份
    selectProvince (value) {
      for (const i in this.areaInfo.province) {
        const item = this.areaInfo.province[i]
        if (item.value == value) {
          this.provinceVal = item.label
          break
        }
      }
      for (const key in this.areaInfo.city) {
        if (key == this.provinceVal) {
          this.cityId = this.areaInfo.city[key][0].value
          this.cityVal = this.areaInfo.city[key][0].label
          break
        }
      }
    },
    // 选择城市
    selectCityInfo (value) {
      for (const i in this.areaInfo.city[this.provinceVal]) {
        const item = this.areaInfo.city[this.provinceVal][i]
        if (item.value == value) {
          this.cityVal = item.label
          break
        }
      }
    }
  }
}
</script>

<style lang="less" scoped>
.checkout {
  padding-bottom: 30px;
  .row {
    .col-lg-2 {
      padding-left: 15px;
      width: 20%;
    }
    .col-lg-10 {
      width: 80%;
    }
  }
}
.container-fluid {
  margin-top: 20px;
}
.iq-search-bar {
  // border-bottom: 1px solid #e7e7e7;
  // padding-bottom: 30px !important;
  // margin-bottom: 30px;
  form {
    display: flex;
    align-items: center;
    p {
      font-size: 14px;
      color: #333;
    }
    span {
      font-size: 14px;
      color: #666;
    }
    label {
      margin-bottom: 0;
    }
    .el-date-editor {
      margin: 0 10px;
      width: 140px;
    }
    .card {
      button {
        margin-bottom: 0 !important;
      }
    }
  }
}
.search-input {
  width: 300px !important;
  margin: 0 50px 0 10px !important;
  background: #eaf5ff !important;
}
.seeAbout {
  width: 100px;
  border-radius: 50px;
}
.reset {
  width: 100px;
  border-radius: 50px;
  background: #c0c4cc;
  margin-left: 10px !important;
}
.selectedClassName {
  padding: 5px 20px;
  margin-right: 10px;
  color: #837af3;
  font-size: 0.8em;
  display: inline-block;
  border-radius: 50px;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
}
.form-control {
  background-color: white !important;
  height: 40px !important;
  width: 150px !important;
  display: inline-block !important;
  margin: 0 20px !important;
}
.card-title {
  margin-bottom: 0 !important;
  font-size: 16px !important;
}
.card {
  width: 150px;
  float: right;
}
table th {
  vertical-align: middle !important;
  text-align: center !important;
  border: 1px solid;
}
tr {
  vertical-align: middle !important;
  text-align: center !important;
  border: 1px solid;
}
td {
  vertical-align: middle !important;
  text-align: center !important;
  border: 1px solid;
}
thead {
  background-color: #79baf2;
}
.txtType {
  width: 100%;
  text-align: center;
  img {
    width: 5rem;
    margin: 2rem auto 1rem;
  }
}
.txtTitle {
  height: 2rem;
  line-height: 2rem;
  font-size: 1em;
  color: #333333;
  padding: 0.5rem 1rem;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
}
.text_16 {
  width: 500px;
  height: 26px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 20px;
  font-family: MicrosoftYaHei-Bold;
  text-align: justify;
  white-space: nowrap;
  line-height: 26px;
  margin-top: 3px;
}
.text-wrapper_6 {
  height: 32px;
  background: url(https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng159b839efdc0bb7c0943d4cbfae8e0e8412fb6755d8969802fb80dff92bfbca1)
    100% no-repeat;
  background-size: 100% 100%;
  width: 100px;
}

.text_17 {
  width: 56px;
  height: 19px;
  overflow-wrap: break-word;
  color: rgba(131, 122, 243, 1);
  font-size: 14px;
  font-family: MicrosoftYaHei;
  text-align: center;
  white-space: nowrap;
  line-height: 19px;
  margin: 7px 0 0 22px;
}
.text-wrapper_7 {
  width: 1410px;
  height: 21px;
  margin: 19px 0 0 30px;
}

.text_18 {
  width: 75px;
  height: 21px;
  overflow-wrap: break-word;
  color: rgba(144, 147, 153, 1);
  font-size: 16px;
  font-family: MicrosoftYaHei;
  text-align: justify;
  white-space: nowrap;
  line-height: 21px;
}

.text_19 {
  width: 496px;
  height: 21px;
  overflow-wrap: break-word;
  color: rgba(144, 147, 153, 1);
  font-size: 16px;
  font-family: MicrosoftYaHei;
  text-align: right;
  white-space: nowrap;
  line-height: 21px;
}
.text_20 {
  width: 1410px;
  height: 78px;
  overflow-wrap: break-word;
  color: rgba(51, 51, 51, 1);
  font-size: 16px;
  font-family: MicrosoftYaHei;
  text-align: justify;
  line-height: 26px;
  margin: 10px 0 30px 30px;
}
.filt-box {
  // width: 250px;
  width: 100%;
  border-radius: 20px !important;
  overflow: hidden;
  box-shadow: 0px 0px 10px 0px rgba(152, 172, 190, 0.2);
  .card {
    width: 100%;
    .title {
      display: flex;
      align-items: center;
      padding: 18px 20px;
      box-sizing: border-box;
      background-color: #fff;
      margin-bottom: 2px;
      img {
        width: 16px;
        margin-right: 10px;
      }
      p {
        font-size: 16px;
      }
    }
    /deep/ .select-box {
      // height: 320px;
      overflow-y: auto;
      padding: 0 20px;
      box-sizing: border-box;
      margin: 16px 0;
      .el-tree {
        .el-tree-node__content {
          display: flex;
          align-items: center;
          margin-bottom: 6px;
          .el-icon-caret-right {
            // padding: 0 0 6px 0;
            font-size: 14px;
            margin-left: 10px;
          }
          .el-checkbox {
            margin-left: -38px;
            margin-top: 8px;

            .el-checkbox__input {
              .el-checkbox__inner {
                font-size: 14px;
              }
              .el-checkbox__inner:hover {
                border: 1px solid #dcdfe6;
              }
            }
            .is-indeterminate {
              .el-checkbox__inner {
                background-color: #827af3;
                border-color: #827af3;
              }
            }
          }
          .is-checked {
            .is-checked {
              .el-checkbox__inner {
                background-color: #827af3;
                border-color: #827af3;
              }
            }
          }
          .el-tree-node__label {
            width: 160px;
            font-size: 14px;
            margin-left: 12px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }
        }
      }
      .card-box {
        .el-checkbox-group {
          display: flex;
          flex-direction: column;
          .el-checkbox {
            margin-bottom: 10px;
            .el-checkbox__label {
              font-size: 14px;
            }
          }
          .is-checked {
            .is-checked {
              .el-checkbox__inner {
                background-color: #827af3;
                border-color: #827af3;
              }
            }
            .el-checkbox__label {
              color: #333;
            }
          }
        }
      }
    }
  }
  .card0 {
    .title {
      background: linear-gradient(270deg, #f0e4fd 0%, #e6e4fd 100%);
      p {
        color: #837af3;
      }
    }
  }
  .card1 {
    .title {
      background: #eefef2;
      p {
        color: #29b446;
      }
    }
  }
  .card2 {
    .title {
      background: #fffbee;
      p {
        color: #ffc22d;
      }
    }
  }

  // ::-webkit-scrollbar {
  //     width: 8px;
  //   }
  //   ::-webkit-scrollbar-thumb {
  //     border-radius: 10px;
  //     box-shadow: inset 0 0 5px #fff;
  //     background: #E9E9E9;
  //   }
  //   ::-webkit-scrollbar-track {
  //     box-shadow: inset 0 0 5px #fff;
  //     border-radius: 0;
  //     background: #fff;
  //   }
}

.form-box {
  width: 97%;
  margin-left: 3%;
  padding: 24px;
  background-color: #fff;
  border-radius: 18px;
}
.all-crumbs {
  margin-bottom: 20px !important;
}
p {
  margin-bottom: 0 !important;
}
.el-pagination {
  display: flex;
  justify-content: center;
  margin-top: 30px;
}
</style>
<style>
.contract {
  border-radius: 10px 10px 0 0;
  overflow: hidden;
  background: linear-gradient(to right, #f0e4fd, #e6e4fd) !important;
  padding: 20px 20px 0;
}
.children {
  padding: 20px;
}
.contract1 {
  border-radius: 0;
  overflow: hidden;
  background: linear-gradient(to right, #edfff1, #edfff1) !important;
  padding: 10px 20px 0;
}
.contract2 {
  border-radius: 0;
  overflow: hidden;
  background: linear-gradient(to right, #fffaef, #fffaef) !important;
  padding: 10px 20px 0;
}
.contract .iq-card-body {
  background: white;
}
</style>
